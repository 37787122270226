import { useContext } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import FormContext from "./FormContext";

const FORMAT = "DD/MM/YYYY";

const DatePicker = ({ label, name, disableFuture, disabled = false }) => {
  const context = useContext(FormContext);

  const change = (momentDate) => {
    context.set(name, momentDate ? momentDate.startOf("day") : null);
  };

  const { value, error } = context.get(name);

  return (
    <DesktopDatePicker
      format={FORMAT}
      value={value || null}
      disableFuture={disableFuture}
      disabled={disabled}
      label={label}
      onChange={change}
      slotProps={{
        textField: {
          placeholder: FORMAT.toLowerCase(),
          helperText: FORMAT.toLowerCase(),
          fullWidth: true,
          size: "small",
          error: !!error,
          ...(error ? { helperText: error } : {})
        },
      }}
    />
  );
};

export default DatePicker;
