import { SelectableWithIdObject } from "src/components/Form/types";
import { BasicResponse } from "./base";
import request from "./request";

interface CategoryResponse {
  id: number;
  name: string;
  status: string;
}

export type CategoryRequest =
  | { name: string; status?: "active" | "passive" }
  | { name?: string; status: "active" | "passive" };

export class Category extends SelectableWithIdObject {
  public name: string;
  public status: string;

  constructor(data: CategoryResponse) {
    super(data.id);
    this.name = data.name;
    this.status = data.status;
  }

  getDisplayName(): string {
    return this.name;
  }
}

export const mapCategory = (data: CategoryResponse): Category => {
  return new Category(data);
};

export const getCategories = async (): Promise<Category[]> => {
  const response = await request(`/categories`);
  return response.map((data: CategoryResponse) => mapCategory(data));
};

export const getCategoryById = async (
  categoryId: number,
): Promise<Category> => {
  const response = await request(`./categories/${categoryId}`);
  return mapCategory(response);
};

export const addCategory = async (
  categoryName: string,
): Promise<BasicResponse> => {
  const response = await request(`/categories`, "POST", {
    name: categoryName,
  });

  return response;
};

export const updateCategory = async (
  categoryId: number,
  newCategory: CategoryRequest,
): Promise<BasicResponse> => {
  const response = await request(
    `/categories/${categoryId}`,
    "PUT",
    newCategory,
  );

  return response;
};

export const deleteCategoryById = async (
  categoryId: number,
): Promise<BasicResponse> => {
  const json = await request(`./categories/${categoryId}`, "DELETE");

  return json;
};
